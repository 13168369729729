import React from 'react';
import { connect, useDispatch } from 'react-redux';
import cx from 'classnames';
import ObservationCreation from '../../observation/Creation/Creation';
import {
  getCurrentStep,
  isObservationCreationModalOpen,
} from '../../../selectors/ui/modal/observation/creation';
import { closeModalAction } from '../../../actions/ui/modal/observation/creation';
import { useTranslation } from 'react-i18next';
import { FaTimes } from 'react-icons/fa';

import styles from './styles.module.scss';
import { Step } from '../../observation/Creation/forms/steps';

const ObservationCreationModal = ({ isOpen, currentStep }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const handleClose = () => {
    dispatch(closeModalAction());
  };

  if (!isOpen) {
    return null;
  }

  return (
    <div className={styles.wrapper}>
      <h5 className={styles.header}>
        <span className={styles.closeButton} onClick={handleClose}>
          <FaTimes />
        </span>
        {t('modale_creation_observation.title')}
      </h5>
      <ul className={styles.stepList}>
        <li
          className={cx(styles.step, {
            [styles.isActive]: currentStep === Step.Culture,
          })}
        >
          <span className={styles.label}>
            {t('modale_creation_observation.culture.title')}
          </span>
        </li>
        <li
          className={cx(styles.step, {
            [styles.isActive]: currentStep === Step.Observation,
          })}
        >
          <span className={styles.label}>
            {t('modale_creation_observation.observation.title')}
          </span>
        </li>
        {/*<li*/}
        {/*  className={cx(styles.step, {*/}
        {/*    [styles.isActive]: currentStep === Step.Equipment,*/}
        {/*  })}*/}
        {/*>*/}
        {/*  <span className={styles.label}>*/}
        {/*    {t('modale_creation_observation.equipment.title')}*/}
        {/*  </span>*/}
        {/*</li>*/}
        <li
          className={cx(styles.step, {
            [styles.isActive]: currentStep === Step.Extra,
          })}
        >
          <span className={styles.label}>
            {t('modale_creation_observation.extra.title')}
          </span>
        </li>
        <li
          className={cx(styles.step, {
            [styles.isActive]: currentStep === Step.User,
          })}
        >
          <span className={styles.label}>
            {t('modale_creation_observation.coordonnees.title')}
          </span>
        </li>
      </ul>
      <div className={styles.formWrapper}>
        <ObservationCreation />
      </div>
    </div>
  );
};

export default connect((state) => ({
  isOpen: isObservationCreationModalOpen(state),
  currentStep: getCurrentStep(state),
}))(ObservationCreationModal);
