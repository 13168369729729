import { useQuery } from '@tanstack/react-query';
import {
  BarleyCategory,
  fetchMaisFourrageObservations,
  WheatCategory,
} from './api';
import {
  fetchBarleyObservations,
  fetchMaisGrainObservations,
  fetchObservation,
  fetchObservations,
  fetchRapeseedObservations,
  fetchSunflowerObservations,
  fetchWheatObservations,
} from './api';
import { useState } from 'react';
import { getCurrentHarvestYear } from '../../configuration/seasons';
import { times } from '../times';

// TODO: [REFACTO] make it truly dynamic and move it to its own thing
function useHarvestYear() {
  const [year] = useState(getCurrentHarvestYear());
  return { year };
}

export function useObservation(id: string) {
  return useQuery({
    queryKey: ['observations', id],
    queryFn: async () => fetchObservation(id),
    staleTime: times.thirtyMinutes,
    gcTime: times.oneHour,
  });
}

export function useObservations(limit: number = 10) {
  const { year } = useHarvestYear();

  return useQuery({
    queryKey: ['observations', { limit }],
    queryFn: async () => fetchObservations(year, limit),
    staleTime: times.fiveMinutes,
    gcTime: times.tenMinutes,
  });
}

export function useRapeseedObservations(enabled: boolean = true) {
  const { year } = useHarvestYear();
  return useQuery({
    queryKey: ['observations', 'rapeseed', { year }],
    queryFn: enabled ? async () => fetchRapeseedObservations(year) : undefined,
    staleTime: times.fiveMinutes,
    gcTime: times.tenMinutes,
  });
}

export function useMaisGrainObservations(enabled: boolean = true) {
  const { year } = useHarvestYear();
  return useQuery({
    queryKey: ['observations', 'mais-grain', { year }],
    queryFn: enabled ? async () => fetchMaisGrainObservations(year) : undefined,
    staleTime: times.fiveMinutes,
    gcTime: times.tenMinutes,
  });
}

export function useMaisFourrageObservations(enabled: boolean = true) {
  const { year } = useHarvestYear();
  return useQuery({
    queryKey: ['observations', 'mais-fourrage', { year }],
    queryFn: enabled
      ? async () => fetchMaisFourrageObservations(year)
      : undefined,
    staleTime: times.fiveMinutes,
    gcTime: times.tenMinutes,
  });
}

export function useWheatObservations(
  category?: WheatCategory,
  enabled: boolean = true
) {
  const { year } = useHarvestYear();
  return useQuery({
    queryKey: ['observations', 'wheat', { category, year }],
    queryFn: enabled
      ? async () => fetchWheatObservations(year, category)
      : undefined,
    staleTime: times.fiveMinutes,
    gcTime: times.tenMinutes,
  });
}

export function useBarleyObservations(
  category?: BarleyCategory,
  enabled: boolean = true
) {
  const { year } = useHarvestYear();
  return useQuery({
    queryKey: ['observations', 'barley', { category, year }],
    queryFn: enabled
      ? async () => fetchBarleyObservations(year, category)
      : undefined,
    staleTime: times.fiveMinutes,
    gcTime: times.tenMinutes,
  });
}

export function useSunflowerObservations(enabled: boolean = true) {
  const { year } = useHarvestYear();
  return useQuery({
    queryKey: ['observations', 'sunflower', { year }],
    queryFn: enabled ? async () => fetchSunflowerObservations(year) : undefined,
    staleTime: times.fiveMinutes,
    gcTime: times.tenMinutes,
  });
}
