import React, { PropsWithChildren } from 'react';
import classNames from 'classnames';
import { ZIndex } from '../../config';

type LayerProps = PropsWithChildren<{
  className?: string;
}>;

export function FloatingLayer({ children, className }: LayerProps) {
  return (
    <div
      className={classNames(
        'tw-absolute tw-left-0 tw-top-0 tw-w-screen tw-h-dvh tw-bg-transparent tw-pointer-events-none',
        'tw-flex tw-flex-col tw-gap-1 tw-p-2.5 md:tw-gap-2',
        className
      )}
      style={{
        zIndex: ZIndex.Floating,
        paddingTop: 80, // 70 for header height + 10 for padding
      }}
    >
      {children}
    </div>
  );
}

type RowProps = PropsWithChildren<{
  className?: string;
}>;

/**
 * A row of floating elements.
 * NOTE: every child should be wrapped in a FloatingCell.
 */
export function FloatingRow({ children, className }: RowProps) {
  return (
    <div
      className={classNames(
        'tw-flex tw-items-center tw-gap-1 md:tw-gap-2',
        className
      )}
    >
      {children}
    </div>
  );
}

type BlockProps = PropsWithChildren<{
  className?: string;
}>;

export function FloatingCell({ className, children }: BlockProps) {
  return (
    <div
      className={classNames(
        'tw-p-0 tw-m-0',
        className,
        'tw-pointer-events-auto' // has to be last so that the user doesn't forget to add it
      )}
    >
      {children}
    </div>
  );
}
