import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FaTimes } from 'react-icons/fa';
import Table from 'react-bootstrap/Table';
import StarRatings from 'react-star-ratings';
import cx from 'classnames';
import {
  getSelectedObservation,
  isObservationDetailsModalOpen,
} from '../../../selectors/ui/modal/observation/details';
import { closeObservationDetailsModalAction } from '../../../actions/ui/modal/observation/details';
import { useTranslation } from 'react-i18next';
import type {
  BarleyCategory,
  WheatCategory,
} from '../../../modules/observations';
import { useObservation } from '../../../modules/observations';
import { Spinner } from '../../common/Spinner';
import { MediaObject } from '../../../modules/observations/api';

import styles from './styles.module.scss';

function isDefined<T>(e: undefined | null | T): e is T {
  return e !== undefined && e !== null;
}

const ObservationDetailsModal = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const isOpen = useSelector((state) => isObservationDetailsModalOpen(state));

  const selected = useSelector((state) => getSelectedObservation(state));
  const { data: observation, status } = useObservation(selected?.id ?? '');

  const images: MediaObject[] = [observation?.image]
    .filter(isDefined)
    .filter((image) => !!image.contentUrl);
  const handleClose = () => {
    dispatch(closeObservationDetailsModalAction());
  };

  if (!isOpen) {
    return null;
  }

  const getCultivationMethod = (method: string) => {
    switch (method) {
      case 'conventional':
        return t('observation.method.conventional');
      case 'simplified cultivation methods':
        return t('observation.method.simplified');
      case 'organic farming':
        return t('observation.method.organic');
      default:
        return t('observation.method.not_applicable');
    }
  };

  const getCultureName = (
    type: string | null,
    category: BarleyCategory | WheatCategory | null
  ) => {
    switch (type) {
      case 'BarleyObservation':
        switch (category) {
          case 'winter':
            return t('observation.culture.winter_barley');
          case 'spring':
            return t('observation.culture.spring_barley');
          default:
            return t('observation.culture.barley');
        }
      case 'WheatObservation':
        switch (category) {
          case 'feed':
            return t('observation.culture.feed_wheat');
          case 'durum':
            return t('observation.culture.durum_wheat');
          default:
            return t('observation.culture.wheat');
        }
      case 'SunflowerObservation':
        return t('observation.culture.sunflower');
      case 'RapeseedObservation':
        return t('observation.culture.rapeseed');
      case 'MaisGrainObservation':
        return t('observation.culture.mais_grain');
      case 'MaisFourrageObservation':
        return t('observation.culture.mais_fourrage');
      default:
        return null;
    }
  };

  const type = observation ? observation['@type'] ?? null : null;
  const category = observation?.category ?? null;
  const culture = getCultureName(type, category);
  const cultivationMethod = getCultivationMethod(
    observation?.cultivationMethod
  );

  function getSoldVolume(soldVolume: string) {
    switch (soldVolume) {
      case 'Rien vendu':
        return t('observation.volume_sold.0');
      case '25% vendu':
        return t('observation.volume_sold.25');
      case '50% vendu':
        return t('observation.volume_sold.50');
      case '75% vendu':
        return t('observation.volume_sold.75');
      case 'Tout vendu':
        return t('observation.volume_sold.100');
      default:
        return soldVolume;
    }
  }

  function getPresenceFusariose(presence: string | null) {
    if (!presence) {
      return presence;
    }

    const values: Record<string, string> = {
      negligible: t('observation.details.presence_fusariose_value.negligible'),
      un_peu: t('observation.details.presence_fusariose_value.un_peu'),
      moyenne: t('observation.details.presence_fusariose_value.moyenne'),
      elevee: t('observation.details.presence_fusariose_value.elevee'),
      tres_elevee: t(
        'observation.details.presence_fusariose_value.tres_elevee'
      ),
    };

    return values[presence] ?? presence;
  }

  function getPresenceSeptoriose(presence: string | null) {
    if (!presence) {
      return presence;
    }

    const values: Record<string, string> = {
      negligible: t('observation.details.presence_septoriose_value.negligible'),
      un_peu: t('observation.details.presence_septoriose_value.un_peu'),
      moyenne: t('observation.details.presence_septoriose_value.moyenne'),
      elevee: t('observation.details.presence_septoriose_value.elevee'),
      tres_elevee: t(
        'observation.details.presence_septoriose_value.tres_elevee'
      ),
    };

    return values[presence] ?? presence;
  }

  return (
    <div className={styles.wrapper}>
      <h5 className={cx(styles.header, styles[type ?? ''] ?? undefined)}>
        <span className={styles.closeButton} onClick={handleClose}>
          <FaTimes />
        </span>
        {culture}
        <span className={styles.small}>
          {observation?.variety && <span> ({observation.variety}) </span>} -{' '}
          {observation?.createdAt &&
            t('observation.details.shared_at', {
              sharedAt: new Date(Date.parse(observation.createdAt.toString())),
            })}
        </span>
      </h5>
      <div className={styles.tableWrapper}>
        {status === 'pending' && (
          <div className="tw-p-10 tw-text-center tw-w-full">
            <Spinner />
          </div>
        )}
        {observation && (
          <Table striped bordered hover className={styles.table}>
            <tbody>
              <tr>
                <td width="175px">
                  <b>{t('observation.details.place')}</b>
                </td>
                <td>{observation.place}</td>
              </tr>
              <tr>
                <td>
                  <b>{t('observation.details.cultivation_method')}</b>
                </td>
                <td>{cultivationMethod}</td>
              </tr>
              <tr>
                <td>
                  <b>{t('observation.details.yield')}</b>
                </td>
                <td>
                  {t(
                    ['MaisFourrageObservation'].includes(type ?? '')
                      ? 'observation.details.yield_value_matiere_seche'
                      : 'observation.details.yield_value',
                    {
                      value: observation.yield,
                    }
                  )}
                </td>
              </tr>
              {Boolean(observation.yieldNotation) && (
                <tr>
                  <td>
                    <b>{t('observation.details.yield_rating')}</b>
                  </td>
                  <td>
                    <StarRatings
                      rating={observation.yieldNotation}
                      starRatedColor="#F37F2B"
                      starDimension="17px"
                      starSpacing="3px"
                    />
                  </td>
                </tr>
              )}
              {'ensilagePlanteEntiere' in observation &&
                typeof observation?.ensilagePlanteEntiere === 'boolean' && (
                  <tr>
                    <td>
                      <b>{t('observation.details.ensilage_plante_entiere')}</b>
                    </td>
                    <td>
                      {observation.ensilagePlanteEntiere
                        ? t('common.yes')
                        : t('common.no')}
                    </td>
                  </tr>
                )}
              {'indicePrecocite' in observation &&
                typeof observation.indicePrecocite === 'number' && (
                  <tr>
                    <td>
                      <b>{t('observation.details.indice_precocite')}</b>
                    </td>
                    <td>{observation.indicePrecocite}</td>
                  </tr>
                )}
              {Boolean(observation.specificWeight) && (
                <tr>
                  <td>
                    <b>{t('observation.details.specific_weight')}</b>
                  </td>
                  <td>
                    {t('observation.details.specific_weight_value', {
                      value: observation.specificWeight,
                    })}
                  </td>
                </tr>
              )}
              {Boolean(observation.protein) && (
                <tr>
                  <td>
                    <b>{t('observation.details.protein')}</b>
                  </td>
                  <td>
                    {t('observation.details.protein_value', {
                      value: observation.protein,
                    })}
                  </td>
                </tr>
              )}
              {Boolean(observation.fallingNumber) && (
                <tr>
                  <td>
                    <b>{t('observation.details.falling_number')}</b>
                  </td>
                  <td>
                    {t('observation.details.falling_number_value', {
                      value: observation.fallingNumber,
                    })}
                  </td>
                </tr>
              )}
              {Boolean(observation.humidity) && (
                <tr>
                  <td>
                    <b>{t('observation.details.humidity')}</b>
                  </td>
                  <td>
                    {t('observation.details.humidity_value', {
                      value: observation.humidity,
                    })}
                  </td>
                </tr>
              )}
              {Boolean(observation.grainsRetainedAfterScreening) && (
                <tr>
                  <td>
                    <b>
                      {t('observation.details.grains_retained_after_screening')}
                    </b>
                  </td>
                  <td>
                    {t(
                      'observation.details.grains_retained_after_screening_value',
                      {
                        value: observation.grainsRetainedAfterScreening,
                      }
                    )}
                  </td>
                </tr>
              )}

              {Boolean(observation.presenceSeptoriose) && (
                <tr>
                  <td>
                    <b>{t('observation.details.presence_septoriose')}</b>
                  </td>
                  <td>
                    {getPresenceSeptoriose(observation.presenceSeptoriose)}
                  </td>
                </tr>
              )}

              {Boolean(observation.presenceFusariose) && (
                <tr>
                  <td>
                    <b>{t('observation.details.presence_fusariose')}</b>
                  </td>
                  <td>{getPresenceFusariose(observation.presenceFusariose)}</td>
                </tr>
              )}

              {Boolean(observation.nitrogenQuantityUsed) && (
                <tr>
                  <td>
                    <b>{t('observation.details.nitrogen_quantity_used')}</b>
                  </td>
                  <td>
                    {t('observation.details.nitrogen_quantity_used_value', {
                      value: observation.nitrogenQuantityUsed,
                    })}
                  </td>
                </tr>
              )}
              {observation.nitrogenProductUsed && (
                <tr>
                  <td>
                    <b>{t('observation.details.nitrogen_product_used')}</b>
                  </td>
                  <td>{observation.nitrogenProductUsed}</td>
                </tr>
              )}
              {observation.soldVolume && (
                <tr>
                  <td>
                    <b>{t('observation.details.volume_sold')}</b>
                  </td>
                  <td>{getSoldVolume(observation.soldVolume)}</td>
                </tr>
              )}
              {observation.sowingWeek && (
                <tr>
                  <td>
                    <b>{t('observation.details.sowing_window')}</b>
                  </td>
                  <td>
                    {t('observation.details.sowing_window_value', {
                      start: new Date(Date.parse(observation.sowingWeek.begin)),
                      end: new Date(Date.parse(observation.sowingWeek.end)),
                    })}
                  </td>
                </tr>
              )}
              {'dateEnsilage' in observation &&
                typeof observation.dateEnsilage === 'string' && (
                  <tr>
                    <td>
                      <b>{t('observation.details.date_ensilage')}</b>
                    </td>
                    <td>
                      {t('observation.details.date_ensilage_value', {
                        value: new Date(Date.parse(observation.dateEnsilage)),
                        formatParams: {
                          value: {
                            year: 'numeric',
                            month: 'numeric',
                            day: 'numeric',
                          },
                        },
                      })}
                    </td>
                  </tr>
                )}
              {observation.comment && (
                <tr>
                  <td>
                    <b>{t('observation.details.comment')}</b>
                  </td>
                  <td>{observation.comment}</td>
                </tr>
              )}
              {observation.combineHarvester?.brand && (
                <tr>
                  <td>
                    <b>{t('observation.details.combine_harvester_brand')}</b>
                  </td>
                  <td>{observation.combineHarvester.brand}</td>
                </tr>
              )}
              {observation.combineHarvester?.model && (
                <tr>
                  <td>
                    <b>{t('observation.details.combine_harvester_model')}</b>
                  </td>
                  <td>{observation.combineHarvester.model}</td>
                </tr>
              )}
              {observation.combineHarvester?.yearOfPurchase && (
                <tr>
                  <td>
                    <b>
                      {t(
                        'observation.details.combine_harvester_year_of_purchase'
                      )}
                    </b>
                  </td>
                  <td>{observation.combineHarvester.yearOfPurchase}</td>
                </tr>
              )}
              {images.map((image) => {
                return (
                  <tr key={image.contentUrl}>
                    <td>
                      <b>{t('observation.details.picture')}</b>
                    </td>
                    <td>
                      <a
                        target="_blank"
                        rel="noopener noreferrer"
                        href={image.contentUrl ?? undefined}
                      >
                        <img
                          className={styles.image}
                          src={image.contentUrl ?? undefined}
                          alt="observation"
                        />
                      </a>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </Table>
        )}
      </div>
    </div>
  );
};

export default ObservationDetailsModal;
