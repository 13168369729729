export const cultures = {
  WHEAT: 'wheat',
  RAPESEED: 'rapeseed',
  SUNFLOWER: 'sunflower',
  BARLEY: 'barley',
  MAIS_GRAIN: 'mais_grain',
  MAIS_FOURRAGE: 'mais_fourrage',
};

export const types = {
  HARVEST: 'harvest',
  INTERVENTION: 'intervention',
  SEEDLING: 'seedling',
};

export const availableTypes = [types.HARVEST];
