import React, { useCallback, useMemo } from 'react';
import { GeoJSON, Pane } from 'react-leaflet';
import * as L from 'leaflet';
import { PaneName, Recolte, ZIndex } from '../../config';
import {
  useBarleyObservations,
  useMaisFourrageObservations,
  useMaisGrainObservations,
  useRapeseedObservations,
  useSunflowerObservations,
  useWheatObservations,
} from './hooks';
import { MinimalObservation } from './api';
import { Feature, FeatureCollection, Point } from 'geojson';
import { useAuthentication } from '../contacts';
import { useDispatch } from 'react-redux';
import * as Icons from './icons';
import { Coordinates } from '../../api/common';
import { closePartnerDetailsModalAction } from '../../actions/ui/modal/partner/details';
import { closeSensorDetailsModalAction } from '../../actions/ui/modal/sensor/details';
import {
  closeObservationDetailsModalAction,
  openObservationDetailsModalAction,
  selectObservationAction,
} from '../../actions/ui/modal/observation/details';
import { List, Map, Seq } from 'immutable';
import { LatLng } from '../../models/latLng';
import { Culture, useLayersConfig } from '../layers';
import * as Modal from '../../components/modals';
import { useConfig } from '../config';

type ObservationProperties = {
  hasPicture: boolean;
};
type ObservationFeature = Feature<Point, ObservationProperties>;

export function ObservationsPane() {
  const { isAuthenticated } = useAuthentication();
  const { recolte } = useConfig();
  const { layers } = useLayersConfig();
  const layer = layers.observations;

  const { data: durumWheatData } = useWheatObservations(
    'durum',
    recolte === 'ete'
  );
  const { data: feedWheatData } = useWheatObservations(
    'feed',
    recolte === 'ete'
  );
  const { data: rapeseedData } = useRapeseedObservations(recolte === 'ete');
  const { data: winterBarleyData } = useBarleyObservations(
    'winter',
    recolte === 'ete'
  );
  const { data: springBarleyData } = useBarleyObservations(
    'spring',
    recolte === 'ete'
  );

  const { data: maisGrainData } = useMaisGrainObservations(
    recolte === 'automne'
  );

  const { data: maisFourrageData } = useMaisFourrageObservations(
    recolte === 'automne'
  );

  const { data: sunflowerData } = useSunflowerObservations(
    recolte === 'automne'
  );

  const observationsEte = [
    ...(durumWheatData ? durumWheatData['hydra:member'] : []),
    ...(feedWheatData ? feedWheatData['hydra:member'] : []),
    ...(rapeseedData ? rapeseedData['hydra:member'] : []),
    ...(winterBarleyData ? winterBarleyData['hydra:member'] : []),
    ...(springBarleyData ? springBarleyData['hydra:member'] : []),
  ];

  const observationsAutomne = [
    ...(maisGrainData ? maisGrainData['hydra:member'] : []),
    ...(maisFourrageData ? maisFourrageData['hydra:member'] : []),
    ...(sunflowerData ? sunflowerData['hydra:member'] : []),
  ];

  const allObservations =
    recolte === 'automne' ? observationsAutomne : observationsEte;

  // @ts-ignore
  const shiftedObservations: Map<string, List<ShiftedObservation>> = Seq(
    allObservations
  )
    .groupBy(
      (observation) =>
        new LatLng({
          latitude: observation.coordinates.latitude ?? null,
          longitude: observation.coordinates.longitude ?? null,
        })
    )
    // .filter((group) => group.count() > 1);
    .map((group) => spreadInCircle(group.toList()))
    .toIndexedSeq()
    .flatten(true)
    // @ts-ignore
    .groupBy((observation: ShiftedObservation) => getCulture(observation));

  const renderKey = computeKey(allObservations, isAuthenticated, recolte);

  function showLayer(culture: null | Culture) {
    return layer === 'all' || layer === culture;
  }

  return (
    <Pane name={PaneName.Observations} style={{ zIndex: ZIndex.Observations }}>
      <CultureLayer
        renderKey={renderKey}
        culture={'colza'}
        visible={showLayer('colza')}
        observations={shiftedObservations.get('colza')}
        icon={Icons.rapeseed}
        iconWithPicture={Icons.rapeseedWithPicture}
      />

      <CultureLayer
        renderKey={renderKey}
        culture={'mais_grain'}
        visible={showLayer('mais_grain')}
        observations={shiftedObservations.get('mais_grain')}
        icon={Icons.corn}
        iconWithPicture={Icons.cornWithPicture}
      />

      <CultureLayer
        renderKey={renderKey}
        culture={'mais_fourrage'}
        visible={showLayer('mais_fourrage')}
        observations={shiftedObservations.get('mais_fourrage')}
        icon={Icons.maisFourrage}
        iconWithPicture={Icons.maisFourrageAvecPhoto}
      />

      <CultureLayer
        renderKey={renderKey}
        culture={'ble_dur'}
        visible={showLayer('ble_dur')}
        observations={shiftedObservations.get('ble_dur')}
        icon={Icons.durumWheat}
        iconWithPicture={Icons.durumWheatWithPicture}
      />

      <CultureLayer
        renderKey={renderKey}
        culture={'ble_tendre'}
        visible={showLayer('ble_tendre')}
        observations={shiftedObservations.get('ble_tendre')}
        icon={Icons.feedWheat}
        iconWithPicture={Icons.feedWheatWithPicture}
      />

      <CultureLayer
        renderKey={renderKey}
        culture={'orge_printemps'}
        visible={showLayer('orge_printemps')}
        observations={shiftedObservations.get('orge_printemps')}
        icon={Icons.springBarley}
        iconWithPicture={Icons.springBarleyWithPicture}
      />

      <CultureLayer
        renderKey={renderKey}
        culture={'orge_hiver'}
        visible={showLayer('orge_hiver')}
        observations={shiftedObservations.get('orge_hiver')}
        icon={Icons.winterBarley}
        iconWithPicture={Icons.winterBarleyWithPicture}
      />

      <CultureLayer
        renderKey={renderKey}
        culture={'tournesol'}
        visible={showLayer('tournesol')}
        observations={shiftedObservations.get('tournesol')}
        icon={Icons.sunflower}
        iconWithPicture={Icons.sunflowerWithPicture}
      />
    </Pane>
  );
}

function spreadInCircle(
  cluster: List<MinimalObservation>
): List<ShiftedObservation> {
  const numberOfSlices = cluster.count();

  if (numberOfSlices <= 1) {
    return cluster;
  }

  const center = cluster.first()?.coordinates as Coordinates;
  const radius = 0.005;
  const angleStep = (2 * Math.PI) / numberOfSlices;

  return cluster.map((observation, slicePosition) => {
    const angle = angleStep * slicePosition;
    return {
      ...observation,
      shiftedCoordinates: {
        latitude: center.latitude + radius * Math.cos(angle),
        longitude: center.longitude + radius * Math.sin(angle),
      },
    };
  });
}

type ShiftedObservation = MinimalObservation & {
  shiftedCoordinates?: Coordinates;
};

type CultureLayerProps = {
  observations?: List<ShiftedObservation>;
  renderKey: string;
  icon: L.Icon;
  iconWithPicture?: L.Icon;
  visible: boolean;
  culture: Culture;
};

function CultureLayer({
  renderKey,
  icon,
  iconWithPicture,
  observations,
  visible,
}: CultureLayerProps) {
  const { isAuthenticated } = useAuthentication();
  const { open } = Modal.useModal();

  const dispatch = useDispatch();

  const openModal = useCallback(
    function (observationId: string) {
      dispatch(closePartnerDetailsModalAction());
      dispatch(closeSensorDetailsModalAction());

      if (isAuthenticated) {
        // @ts-ignore
        dispatch(selectObservationAction({ id: observationId }));
        dispatch(openObservationDetailsModalAction());
      } else {
        dispatch(closeObservationDetailsModalAction());
        open(Modal.Id.CreationContact);
      }
    },
    [isAuthenticated, dispatch, open]
  );

  const layer = useMemo(() => {
    const geoJson = observations ? makePointCollection(observations) : null;
    return (
      geoJson && (
        <GeoJSON
          // key is required to force a re-render when the data changes
          key={renderKey}
          pane={PaneName.Observations}
          data={geoJson}
          eventHandlers={{
            click: (e) => {
              openModal(e.layer.feature.properties.observationId);
            },
          }}
          pointToLayer={(feature: ObservationFeature, latlng) => {
            return L.marker(latlng, {
              icon: feature.properties.hasPicture ? iconWithPicture : icon,
              opacity: isAuthenticated ? 1 : 0.4,
              zIndexOffset: ZIndex.Observations,
            });
          }}
        />
      )
    );
  }, [
    observations,
    openModal,
    renderKey,
    icon,
    iconWithPicture,
    isAuthenticated,
  ]);

  return visible ? layer : null;
}

function makePointCollection<T extends ShiftedObservation>(
  data: List<T>
): FeatureCollection<Point, ObservationProperties> {
  return {
    type: 'FeatureCollection',
    features: data.toArray().map((observation: ShiftedObservation) => {
      const coordinates =
        observation.shiftedCoordinates ?? observation.coordinates;
      return {
        type: 'Feature',
        geometry: {
          type: 'Point',
          coordinates: [coordinates.longitude, coordinates.latitude],
        },
        properties: {
          color: '#000',
          observationId: observation.id,
          hasPicture: observation.picture,
        },
      };
    }),
  };
}

function computeKey(
  data: undefined | Array<unknown>,
  isAuthenticated: boolean = false,
  recolte: Recolte
): string {
  const count = data?.length ?? 0;
  return `observation-${recolte}-${count}-${
    isAuthenticated ? 'auth' : 'no-auth'
  }`;
}

// data => combined data => shuffled data => geojson => component
function getCulture(observation: MinimalObservation): null | Culture {
  switch (observation['@type']) {
    case 'WheatObservation':
      switch (observation.category) {
        case 'durum':
          return 'ble_dur';
        case 'feed':
          return 'ble_tendre';
        default:
          return null;
      }
    case 'BarleyObservation':
      switch (observation.category) {
        case 'winter':
          return 'orge_hiver';
        case 'spring':
          return 'orge_printemps';
        default:
          return null;
      }
    case 'RapeseedObservation':
      return 'colza';
    case 'MaisGrainObservation':
      return 'mais_grain';
    case 'MaisFourrageObservation':
      return 'mais_fourrage';
    case 'SunflowerObservation':
      return 'tournesol';
    default:
      return null;
  }
}
