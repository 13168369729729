import { useTranslation } from 'react-i18next';
import { Field } from 'redux-form';
import RatingInput from '../../../common/form/RatingInput';
import FieldInput from '../../../common/form/FieldInput';
import SelectInput from '../../../common/form/SelectInput';
import TownInputContainer from '../../../common/form/TownInput/TownInputContainer';
import cx from 'classnames';
import styles from './styles.module.scss';
import { useLocation } from '../../../../modules/location/useLocation';
import { usePlace } from '../../../../modules/place/usePlace';
import LatLng from '../../../../models/latLng';
import { useEmail, usePhone } from '../../../../modules/contacts';
import MediaImageInputContainer from '../../../common/form/MediaImageInput/MediaImageInputContainer';
import React from 'react';
import HorizontalChoiceInput from '../../../common/form/HorizontalChoiceInput';

const DEFAULT_STEP = 0.1;

export function RatingField() {
  const { t } = useTranslation();

  return (
    <Field
      name="yieldNotation"
      type="number"
      component={RatingInput}
      label={t('modale_creation_observation.yield_rating_label')}
      legends={{
        1: t('observation.yield_rating.1'),
        2: t('observation.yield_rating.2'),
        3: t('observation.yield_rating.3'),
        4: t('observation.yield_rating.4'),
        5: t('observation.yield_rating.5'),
      }}
      required
    />
  );
}

export function ZipcodeField() {
  const { t } = useTranslation();
  const { changeLocation } = useLocation();
  const { changePlace } = usePlace();

  function handleChange(event) {
    const place = event.value;
    const location = new LatLng({
      longitude: place.coordinates.longitude,
      latitude: place.coordinates.latitude,
    });
    changePlace(place);
    changeLocation(location);
  }

  return (
    <Field
      name="place"
      type="select"
      component={TownInputContainer}
      label={t('modale_creation_observation.observation.place_label')}
      formName="observation"
      placeholder={t(
        'modale_creation_observation.observation.place_placeholder'
      )}
      required
      onChange={handleChange}
    />
  );
}

export function SpecificWeightField() {
  const { t } = useTranslation();
  return (
    <Field
      name="specificWeight"
      type="number"
      component={FieldInput}
      min={0}
      step={DEFAULT_STEP}
      inputMode="decimal"
      label={t('modale_creation_observation.observation.specific_weight_label')}
      append={t('modale_creation_observation.observation.specific_weight_unit')}
    />
  );
}

export function YieldField({ unite }) {
  const { t } = useTranslation();

  return (
    <Field
      name="yield"
      type="number"
      component={FieldInput}
      min={0}
      step={DEFAULT_STEP}
      inputMode="decimal"
      label={t('modale_creation_observation.observation.yield_label')}
      append={unite ?? t('modale_creation_observation.observation.yield_unit')}
      required
    />
  );
}

export function GrainsRetainedAfterScreeningField() {
  const { t } = useTranslation();

  return (
    <Field
      name="grainsRetainedAfterScreening"
      type="number"
      component={FieldInput}
      min={0}
      max={100}
      step={DEFAULT_STEP}
      inputMode="decimal"
      label={t('modale_creation_observation.observation.calibration_label')}
      append={t('modale_creation_observation.observation.calibration_unit')}
    />
  );
}

export function FallingNumberField() {
  const { t } = useTranslation();
  return (
    <Field
      name="fallingNumber"
      type="number"
      component={FieldInput}
      min={0}
      step={DEFAULT_STEP}
      inputMode="decimal"
      label={t('modale_creation_observation.observation.falling_number_label')}
      append={t('modale_creation_observation.observation.falling_number_unit')}
    />
  );
}

export function ProteinField() {
  const { t } = useTranslation();
  return (
    <Field
      name="protein"
      type="number"
      component={FieldInput}
      min={0}
      step={DEFAULT_STEP}
      inputMode="decimal"
      label={t('modale_creation_observation.observation.protein_label')}
      append={t('modale_creation_observation.observation.protein_unit')}
    />
  );
}

export function HumidityField() {
  const { t } = useTranslation();

  return (
    <Field
      name="humidity"
      type="number"
      component={FieldInput}
      min={0}
      step={DEFAULT_STEP}
      inputMode="decimal"
      label={t('modale_creation_observation.observation.humidity_label')}
      append={t('modale_creation_observation.observation.humidity_unit')}
    />
  );
}

export function CultivationMethodField() {
  const { t } = useTranslation();
  return (
    <Field
      name="cultivationMethod"
      type="select"
      component={SelectInput}
      label={t(
        'modale_creation_observation.observation.cultivation_method_label'
      )}
      required
      choices={[
        {
          value: 'conventional',
          label: t('observation.method.conventional'),
        },
        {
          value: 'simplified cultivation methods',
          label: t('observation.method.simplified'),
        },
        {
          value: 'organic farming',
          label: t('observation.method.organic'),
        },
      ]}
    />
  );
}

export function VarietyField() {
  const { t } = useTranslation();
  return (
    <Field
      name="variety"
      type="text"
      component={FieldInput}
      label={t('modale_creation_observation.observation.variety_label')}
      required
    />
  );
}

export function EnsilagePlanteEntiereField() {
  const { t } = useTranslation();
  return (
    <Field
      name="ensilagePlanteEntiere"
      type="select"
      component={SelectInput}
      label={t(
        'modale_creation_observation.observation.ensilage_plante_entiere_label'
      )}
      choices={[
        {
          value: true,
          label: t('common.yes'),
        },
        {
          value: false,
          label: t('common.no'),
        },
      ]}
    />
  );
}

export function IndicePrecociteField() {
  const { t } = useTranslation();
  return (
    <Field
      name="indicePrecocite"
      type="number"
      step={1}
      inputMode="decimal"
      component={FieldInput}
      min={0}
      label={t(
        'modale_creation_observation.observation.indice_precocite_label'
      )}
    />
  );
}

export function NitrogenProductField() {
  const { t } = useTranslation();
  return (
    <Field
      name="nitrogenProductUsed"
      type="select"
      component={SelectInput}
      label={t(
        'modale_creation_observation.observation.nitrogen_product_used_label'
      )}
      choices={[
        {
          value: t('observation.fertilizer.uan'),
          label: t('observation.fertilizer.uan'),
        },
        {
          value: t('observation.fertilizer.ammonium_nitrate'),
          label: t('observation.fertilizer.ammonium_nitrate'),
        },
        {
          value: t('observation.fertilizer.urea'),
          label: t('observation.fertilizer.urea'),
        },
        {
          value: t('observation.fertilizer.other'),
          label: t('observation.fertilizer.other'),
        },
      ]}
    />
  );
}

export function NitrogenQuantityField() {
  const { t } = useTranslation();
  return (
    <Field
      name="nitrogenQuantityUsed"
      type="number"
      step={DEFAULT_STEP}
      inputMode="decimal"
      component={FieldInput}
      min={0}
      label={t(
        'modale_creation_observation.observation.nitrogen_quantity_used_label'
      )}
      append={t(
        'modale_creation_observation.observation.nitrogen_quantity_used_unit'
      )}
    />
  );
}

export function TargetPriceField() {
  const { t } = useTranslation();

  return (
    <Field
      name="targetPrice"
      type="number"
      step={DEFAULT_STEP}
      inputMode="decimal"
      component={FieldInput}
      min={0}
      label={t('modale_creation_observation.observation.target_price_label')}
      append={t('modale_creation_observation.observation.target_price_unit')}
    />
  );
}

export function VolumeSoldField() {
  const { t } = useTranslation();

  return (
    <Field
      name="soldVolume"
      type="select"
      component={SelectInput}
      choices={[
        { value: 'Rien vendu', label: t('observation.volume_sold.0') },
        { value: '25% vendu', label: t('observation.volume_sold.25') },
        { value: '50% vendu', label: t('observation.volume_sold.50') },
        { value: '75% vendu', label: t('observation.volume_sold.75') },
        {
          value: 'Tout vendu',
          label: t('observation.volume_sold.100'),
        },
      ]}
      label={t('modale_creation_observation.observation.volume_sold_label')}
    />
  );
}

export function DateEnsilageField() {
  const { t } = useTranslation();
  return (
    <Field
      name="dateEnsilage"
      type="date"
      component={FieldInput}
      label={t('modale_creation_observation.observation.date_ensilage_label')}
    />
  );
}

export function SowingWindowField() {
  const { t } = useTranslation();
  return (
    <>
      <label>
        {t('modale_creation_observation.observation.sowing_window_label')}
      </label>
      <div className={cx(styles.periodInputGroup, 'input-group')}>
        <Field
          name="sowingWeekBegin"
          type="date"
          component={FieldInput}
          prepend={t(
            'modale_creation_observation.observation.sowing_window_start'
          )}
        />
        <Field
          name="sowingWeekEnd"
          type="date"
          component={FieldInput}
          prepend={t(
            'modale_creation_observation.observation.sowing_window_end'
          )}
        />
      </div>
    </>
  );
}

export function YieldRatingField() {
  const { t } = useTranslation();
  return (
    <Field
      name="yieldNotation"
      type="number"
      component={RatingInput}
      label={t('modale_creation_observation.observation.yield_rating_label')}
      legends={{
        1: t('observation.yield_rating.1'),
        2: t('observation.yield_rating.2'),
        3: t('observation.yield_rating.3'),
        4: t('observation.yield_rating.4'),
        5: t('observation.yield_rating.5'),
      }}
      required
    />
  );
}

export function EmailField() {
  const { t } = useTranslation();
  const { changeEmail } = useEmail();

  function onChange(event) {
    const email = event.target.value;
    changeEmail(email);
  }

  return (
    <Field
      name="email"
      id="email"
      type="email"
      autoComplete="email"
      component={FieldInput}
      label={t('form.email_input.label')}
      required
      onChange={onChange}
    />
  );
}

export function PhoneField() {
  const { t } = useTranslation();
  const { changePhone } = usePhone();

  function onChange(event) {
    const phone = event.target.value;
    changePhone(phone);
  }

  return (
    <Field
      name="phone"
      id="phone"
      type="tel"
      autoComplete="tel"
      component={FieldInput}
      label={t('form.phone_input.label')}
      onChange={onChange}
    />
  );
}

const brands = [
  { value: 'Claas', label: 'Claas' },
  { value: 'John Deere', label: 'John Deere' },
  { value: 'New Holland', label: 'New Holland' },
  { value: 'Case IH', label: 'Case IH' },
  { value: 'Massey Ferguson', label: 'Massey Ferguson' },
  { value: 'Fendt', label: 'Fendt' },
  { value: 'Deutz-Fahr', label: 'Deutz-Fahr' },
  { value: 'Laverda', label: 'Laverda' },
  { value: 'Autre Marque', label: 'Autre Marque' },
];

export function CombineHarvesterBrandField() {
  const { t } = useTranslation();
  return (
    <Field
      // required={true}
      name="combineHarvester.brand"
      type="select"
      component={SelectInput}
      label={t(
        'modale_creation_observation.equipment.combine_harvester_brand_label'
      )}
      choices={brands}
    />
  );
}

export function CombineHarvesterModelField() {
  const { t } = useTranslation();
  return (
    <Field
      name="combineHarvester.model"
      type="text"
      component={FieldInput}
      label={t(
        'modale_creation_observation.equipment.combine_harvester_model_label'
      )}
    />
  );
}

export function CombineHarvesterYearOfPurchaseField() {
  const { t } = useTranslation();
  return (
    <Field
      name="combineHarvester.yearOfPurchase"
      type="number"
      min={1950}
      max={2030}
      component={FieldInput}
      label={t(
        'modale_creation_observation.equipment.combine_harvester_year_of_purchase_label'
      )}
    />
  );
}

export function CombineHarvesterImageField() {
  const { t } = useTranslation();
  return (
    <Field
      name="combineHarvester.image"
      formName="observation"
      component={MediaImageInputContainer}
      label={t(
        'modale_creation_observation.equipment.combine_harvester_image_label'
      )}
      feedback={t(
        'modale_creation_observation.equipment.combine_harvester_image_feedback'
      )}
    />
  );
}

export function CombineHarvesterBreakdownField() {
  const { t } = useTranslation();
  return (
    <Field
      name="combineHarvester.hadBreakdown"
      type="select"
      component={SelectInput}
      label={t(
        'modale_creation_observation.equipment.combine_harvester_breakdown_label'
      )}
      normalize={(value) => {
        if (value === 'true' || value === true) {
          return true;
        }

        if (value === 'false' || value === false) {
          return false;
        }

        return null;
      }}
      choices={[
        {
          value: true,
          label: t('common.yes'),
        },
        {
          value: false,
          label: t('common.no'),
        },
      ]}
    />
  );
}

export function CombineHarvesterBreakdownDurationField() {
  const { t } = useTranslation();

  return (
    <Field
      name="combineHarvester.breakdownDuration"
      type="select"
      component={SelectInput}
      label={t(
        'modale_creation_observation.equipment.combine_harvester_breakdown_duration_label'
      )}
      choices={[
        {
          value: 'less_than_one_hour',
          label: t('observation.breakdown.less_than_one_hour'),
        },
        {
          value: 'less_than_a_half_day',
          label: t('observation.breakdown.less_than_a_half_day'),
        },
        {
          value: 'less_than_a_day',
          label: t('observation.breakdown.less_than_a_day'),
        },
        {
          value: 'one_to_two_days',
          label: t('observation.breakdown.one_to_two_days'),
        },
        {
          value: 'more_than_two_days',
          label: t('observation.breakdown.more_than_two_days'),
        },
      ]}
    />
  );
}

export function PresenceSeptorioseField() {
  const { t } = useTranslation();
  return (
    <Field
      name="presenceSeptoriose"
      component={HorizontalChoiceInput}
      label={t(
        'modale_creation_observation.observation.presence_septoriose_label'
      )}
      choices={[
        {
          value: 'negligible',
          label: t(
            'modale_creation_observation.observation.presence_septoriose_value.negligible'
          ),
        },
        {
          value: 'un_peu',
          label: t(
            'modale_creation_observation.observation.presence_septoriose_value.un_peu'
          ),
        },
        {
          value: 'moyenne',
          label: t(
            'modale_creation_observation.observation.presence_septoriose_value.moyenne'
          ),
        },
        {
          value: 'elevee',
          label: t(
            'modale_creation_observation.observation.presence_septoriose_value.elevee'
          ),
        },
        {
          value: 'tres_elevee',
          label: t(
            'modale_creation_observation.observation.presence_septoriose_value.tres_elevee'
          ),
        },
      ]}
    />
  );
}

export function PresenceFusarioseField() {
  const { t } = useTranslation();
  return (
    <Field
      name="presenceFusariose"
      component={HorizontalChoiceInput}
      label={t(
        'modale_creation_observation.observation.presence_fusariose_label'
      )}
      choices={[
        {
          value: 'negligible',
          label: t(
            'modale_creation_observation.observation.presence_fusariose_value.negligible'
          ),
        },
        {
          value: 'un_peu',
          label: t(
            'modale_creation_observation.observation.presence_fusariose_value.un_peu'
          ),
        },
        {
          value: 'moyenne',
          label: t(
            'modale_creation_observation.observation.presence_fusariose_value.moyenne'
          ),
        },
        {
          value: 'elevee',
          label: t(
            'modale_creation_observation.observation.presence_fusariose_value.elevee'
          ),
        },
        {
          value: 'tres_elevee',
          label: t(
            'modale_creation_observation.observation.presence_fusariose_value.tres_elevee'
          ),
        },
      ]}
    />
  );
}
