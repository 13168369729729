import React from 'react';

import * as Sentry from '@sentry/react';
import ObservationForm from './forms/ObservationForm';
import UserForm from './forms/UserForm';
import CultureSelection from './CultureSelection';

import {
  closeModalAction,
  nextStepAction,
  previousStepAction,
  setStepAction,
} from '../../../actions/ui/modal/observation/creation';
import { useDispatch, useSelector } from 'react-redux';
import {
  getCulture,
  getCurrentStep,
} from '../../../selectors/ui/modal/observation/creation';
import ExtraForm from './forms/ExtraForm';
import { useAuthentication } from '../../../modules/contacts';
import { useQueryClient } from '@tanstack/react-query';
import { destroy, Form, SubmissionError } from 'redux-form';
import { submit } from '../../../modules/observations/form';
import { ClientError, UnprocessableEntityError } from '../../../api/common';
import { setAskedPositionAction } from '../../../actions/askedPosition';
import LatLng from '../../../models/latLng';
import { createToastAction } from '../../../actions/ui/toast';
import Toast from '../../../models/toast';
import { getCountryCode } from '../../../config';
import { Step } from './forms/steps';

// TODO: [REFACTORING] Clean up all this mess
function Creation() {
  const { authenticate } = useAuthentication();
  const dispatch = useDispatch();
  const queryClient = useQueryClient();

  const currentStep = useSelector((state) => getCurrentStep(state));
  const culture = useSelector((state) => getCulture(state));

  async function onSubmit(form: Form) {
    try {
      const response = await submit(culture, form);
      console.debug('[App] Observation created', response);
      authenticate();
      dispatch(makeSuccessToastAction());
      dispatch(closeModalAction());
      // @ts-ignore
      dispatch(setStepAction(Step.Culture));
      dispatch(destroy('observation'));
      dispatch(
        setAskedPositionAction(
          // @ts-ignore
          new LatLng({
            latitude: response.coordinates.latitude,
            longitude: response.coordinates.longitude,
          })
        )
      );

      await queryClient.invalidateQueries({ queryKey: ['observations'] });
      // No need to wait for this one to finish
      queryClient.invalidateQueries({ queryKey: ['rankings'] });
    } catch (error) {
      console.error('[App] Error while creating observation', error);

      Sentry.captureException(error);

      if (error instanceof UnprocessableEntityError) {
        dispatch(makeFormErrorToastAction(error));
        throw new SubmissionError(error.toFinalFormErrors());
      }

      if (error instanceof ClientError) {
        dispatch(makeFormErrorToastAction(error));
        throw new SubmissionError({
          _error: error.json['hydra:description'],
        });
      }

      dispatch(makeGenericErrorToastAction());
      throw new SubmissionError({
        _error:
          getCountryCode() === 'fr'
            ? 'Une erreur est survenue, merci de réessayer ultérieurement'
            : 'An error occurred, please try again later.',
      });
    }
  }

  const nextStep = () => {
    dispatch(nextStepAction());
  };

  const previousStep = () => {
    dispatch(previousStepAction());
  };

  return (
    <div>
      {/* @ts-ignore */}
      {currentStep === Step.Culture && <CultureSelection />}
      {currentStep === Step.Observation && (
        <ObservationForm previousStep={previousStep} onSubmit={nextStep} />
      )}
      {/*{currentStep === Step.Equipment && (*/}
      {/*  // @ts-ignore*/}
      {/*  <EquipmentForm previousStep={previousStep} onSubmit={nextStep} />*/}
      {/*)}*/}
      {currentStep === Step.Extra && (
        // @ts-ignore
        <ExtraForm previousStep={previousStep} onSubmit={nextStep} />
      )}
      {currentStep === Step.User && (
        // @ts-ignore
        <UserForm previousStep={previousStep} onSubmit={onSubmit} />
      )}
    </div>
  );
}

export default Creation;

function makeSuccessToastAction() {
  return createToastAction(
    // @ts-ignore
    new Toast({
      title: getCountryCode() === 'fr' ? 'Nouvelle observation' : 'New harvest',
      body:
        getCountryCode() === 'fr'
          ? 'Votre observation a été ajoutée avec succès !'
          : 'Your harvest was added successfully!',
      variant: 'success',
    })
  );
}

function makeFormErrorToastAction(
  error: UnprocessableEntityError | ClientError
) {
  return createToastAction(
    // @ts-ignore
    new Toast({
      title:
        getCountryCode() === 'fr'
          ? 'Vérifier vos informations'
          : 'Please check the entered information',
      body: error.json['hydra:description'],
      variant: 'danger',
    })
  );
}

function makeGenericErrorToastAction() {
  return createToastAction(
    // @ts-ignore
    new Toast({
      title: 'Oops',
      body:
        getCountryCode() === 'fr'
          ? 'Une erreur est survenue, merci de réessayer ultérieurement'
          : 'An error occurred, please try again later.',
      variant: 'danger',
    })
  );
}
