import React, {
  createContext,
  PropsWithChildren,
  useContext,
  useState,
} from 'react';

// TODO: Séparer la culture des filtres. E.g. faire 3 propriétés : culture ('ble_tendre', 'ble_dur', ...), observations ('toutes', 'culture'), previsions ('rendement', 'proteine', ...)

export type Layers = {
  previsions: PrevisionsLayer;
  observations: ObservationsLayer;
};

type Hide = 'hide';
type All = 'all';

// ATTENTION: Pour activer une couche, il faut qu'elle soit ajoutée dans le menu et dans le pane (cf les composants PrevisionsMenu et PrevisionsPane)
type PrevisionsLayer =
  | Hide
  // Rendement
  | 'rendement_ble_tendre'
  | 'rendement_ble_dur'
  | 'rendement_colza'
  | 'rendement_orge_hiver'
  | 'rendement_orge_printemps'
  | 'rendement_tournesol'
  | 'rendement_mais_grain'
  | 'rendement_mais_fourrage'
  // Différence de rendement p/r aux 5 dernières années
  | 'evolution_rendement_ble_tendre'
  | 'evolution_rendement_ble_dur'
  | 'evolution_rendement_colza'
  | 'evolution_rendement_orge_hiver'
  | 'evolution_rendement_orge_printemps'
  | 'evolution_rendement_tournesol'
  | 'evolution_rendement_mais_grain'
  | 'evolution_rendement_mais_fourrage'
  // Proteine
  | 'proteine_ble_tendre'
  | 'proteine_ble_dur'
  | 'proteine_colza'
  | 'proteine_orge_hiver'
  | 'proteine_orge_printemps'
  | 'proteine_tournesol'
  | 'proteine_mais_grain'
  | 'proteine_mais_fourrage'
  // Ps
  | 'poids_specifique_ble_tendre'
  | 'poids_specifique_ble_dur'
  | 'poids_specifique_colza'
  | 'poids_specifique_orge_hiver'
  | 'poids_specifique_orge_printemps'
  | 'poids_specifique_tournesol'
  | 'poids_specifique_mais_grain'
  | 'poids_specifique_mais_fourrage'
  // Humidité
  | 'humidite_ble_tendre'
  | 'humidite_ble_dur'
  | 'humidite_colza'
  | 'humidite_orge_hiver'
  | 'humidite_orge_printemps'
  | 'humidite_tournesol'
  | 'humidite_mais_grain'
  | 'humidite_mais_fourrage';

// TODO: combine this with the Cultures constant
export type Culture = CultureEte | CultureAutomne;

export type CultureEte =
  | 'colza'
  | 'ble_tendre'
  | 'ble_dur'
  | 'orge_printemps'
  | 'orge_hiver';

export type CultureAutomne = 'tournesol' | 'mais_grain' | 'mais_fourrage';

export function isCultureEte(culture: Culture): culture is CultureEte {
  return [
    'colza',
    'ble_tendre',
    'ble_dur',
    'orge_printemps',
    'orge_hiver',
  ].includes(culture);
}

export function isCultureAutomne(culture: Culture): culture is CultureAutomne {
  return ['tournesol', 'mais_grain', 'mais_fourrage'].includes(culture);
}

type ObservationsLayer = All | Culture;

type LayersConfig = {
  layers: Layers;
  change(layers: Partial<Layers>): void;
};

// Create the context
const LayersConfigContext = createContext<LayersConfig | null>(null);

// Create the provider component
export function LayersConfigProvider({ children }: PropsWithChildren) {
  const [layers, setLayers] = useState<Layers>({
    previsions: 'hide',
    observations: 'all',
  });

  const contextValue: LayersConfig = {
    layers,
    change: (layers: Partial<Layers>) => {
      console.debug('[Layers] Layer change requested', layers);
      return setLayers((prev) => ({
        ...prev,
        ...layers,
      }));
    },
  };

  return (
    <LayersConfigContext.Provider value={contextValue}>
      {children}
    </LayersConfigContext.Provider>
  );
}

export function useLayersConfig(): LayersConfig {
  const context = useContext(LayersConfigContext);

  if (!context) {
    throw new Error(
      'useLayersConfig must be used within a LayersConfigProvider'
    );
  }

  return context;
}
