import React, { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { PiPlant } from 'react-icons/pi';
import { FaEyeSlash } from 'react-icons/fa';
import { MapButton } from '../../components/buttons';
import * as Modal from '../../components/modals';

import * as Dropdown from '../../components/dropdown';
import { Layers, useLayersConfig } from '../layers';
import { getCurrentHarvestYear } from '../../configuration/seasons';
import { getEchelleForLayer, palette } from './colors';
import { useConfig } from '../config';

export function PrevisionsLegendeModalButton() {
  const { t } = useTranslation();
  const { layers } = useLayersConfig();
  const { open } = Modal.useModal();

  if (layers.previsions === 'hide') {
    return null;
  }

  const echelle = getEchelleForLayer(layers.previsions);
  if (!echelle) {
    return null;
  }

  return (
    <MapButton
      className="tw-flex tw-items-center tw-justify-center"
      onClick={() => open(Modal.Id.PrevisionsLegende)}
    >
      {t('previsions.button.label')}
    </MapButton>
  );
}

export function PrevisionsLegendeCard() {
  const { layers } = useLayersConfig();

  if (layers.previsions === 'hide') {
    return null;
  }

  const echelle = getEchelleForLayer(layers.previsions);
  if (!echelle) {
    return null;
  }

  return (
    <div className="tw-card tw-bg-base-100 tw-shadow-xl">
      <div className="tw-card-body">
        <PrevisionsLegende />
      </div>
    </div>
  );
}

export function PrevisionsMenu() {
  const { t } = useTranslation();
  const { recolte } = useConfig();

  return (
    <Dropdown.Dropdown>
      <Dropdown.Button>
        <PiPlant className="tw-hidden lg:tw-block" />
        <span>{t('previsions.menu.label')}</span>
      </Dropdown.Button>

      <Dropdown.List className="tw-96">
        <LayerToggleItem
          className="!tw-font-normal"
          layer={'hide'}
          label={t('previsions.menu.item.cacher')}
          image={<FaEyeSlash className={'tw-w-5 tw-min-h-5 tw-mx-0.5'} />}
        />

        {recolte === 'ete' && (
          <li>
            <details>
              <summary>
                <img
                  className="tw-w-7 tw-min-h-6 tw-h-auto"
                  src="https://res.cloudinary.com/comparateuragricole/image/upload/v1689182255/moisson-live/markers/rapeseed.png"
                  alt={'Marker colza'}
                />
                {t('previsions.menu.item.titre_colza')}
              </summary>
              <ul>
                <LayerToggleItem
                  layer={'rendement_colza'}
                  label={t('previsions.menu.item.rendement_colza')}
                />
                <LayerToggleItem
                  layer={'evolution_rendement_colza'}
                  label={t('previsions.menu.item.evolution_rendement_colza')}
                />
              </ul>
            </details>
          </li>
        )}

        {recolte === 'ete' && (
          <li>
            <details>
              <summary>
                <img
                  className="tw-w-7 tw-min-h-6 tw-h-auto"
                  src="https://res.cloudinary.com/comparateuragricole/image/upload/v1689182255/moisson-live/markers/feed-wheat.png"
                  alt={'Marker blé tendre'}
                />
                {t('previsions.menu.item.titre_ble_tendre')}
              </summary>
              <ul>
                <LayerToggleItem
                  layer={'rendement_ble_tendre'}
                  label={t('previsions.menu.item.rendement_ble_tendre')}
                />
                <LayerToggleItem
                  layer={'evolution_rendement_ble_tendre'}
                  label={t(
                    'previsions.menu.item.evolution_rendement_ble_tendre'
                  )}
                />
                <LayerToggleItem
                  layer={'poids_specifique_ble_tendre'}
                  label={t('previsions.menu.item.poids_specifique_ble_tendre')}
                />
                <LayerToggleItem
                  layer={'proteine_ble_tendre'}
                  label={t('previsions.menu.item.proteine_ble_tendre')}
                />
              </ul>
            </details>
          </li>
        )}

        {recolte === 'ete' && (
          <li>
            <details>
              <summary>
                <img
                  className="tw-w-7 tw-min-h-6 tw-h-auto"
                  src="https://res.cloudinary.com/comparateuragricole/image/upload/v1689182255/moisson-live/markers/winter-barley.png"
                  alt={"Marker orge d'hiver"}
                />
                {t('previsions.menu.item.titre_orge_hiver')}
              </summary>
              <ul>
                <LayerToggleItem
                  layer={'rendement_orge_hiver'}
                  label={t('previsions.menu.item.rendement_orge_hiver')}
                />
                <LayerToggleItem
                  layer={'evolution_rendement_orge_hiver'}
                  label={t(
                    'previsions.menu.item.evolution_rendement_orge_hiver'
                  )}
                />
                <LayerToggleItem
                  layer={'poids_specifique_orge_hiver'}
                  label={t('previsions.menu.item.poids_specifique_orge_hiver')}
                />
              </ul>
            </details>
          </li>
        )}

        {recolte === 'ete' && (
          <li>
            <details>
              <summary>
                <img
                  className="tw-w-7 tw-min-h-6 tw-h-auto"
                  src="https://res.cloudinary.com/comparateuragricole/image/upload/v1689182255/moisson-live/markers/spring-barley.png"
                  alt={'Marker orge de printemps'}
                />
                {t('previsions.menu.item.titre_orge_printemps')}
              </summary>
              <ul>
                <LayerToggleItem
                  layer={'rendement_orge_printemps'}
                  label={t('previsions.menu.item.rendement_orge_printemps')}
                />
                <LayerToggleItem
                  layer={'evolution_rendement_orge_printemps'}
                  label={t(
                    'previsions.menu.item.evolution_rendement_orge_printemps'
                  )}
                />
              </ul>
            </details>
          </li>
        )}

        {recolte === 'automne' && (
          <li>
            <details>
              <summary>
                <img
                  className="tw-w-7 tw-min-h-6 tw-h-auto"
                  src="https://res.cloudinary.com/comparateuragricole/image/upload/v1689182255/moisson-live/markers/sunflower.png"
                  alt={'Marker tournesol'}
                />
                {t('previsions.menu.item.titre_tournesol')}
              </summary>
              <ul>
                <LayerToggleItem
                  layer={'rendement_tournesol'}
                  label={t('previsions.menu.item.rendement_tournesol')}
                />
                <LayerToggleItem
                  layer={'evolution_rendement_tournesol'}
                  label={t(
                    'previsions.menu.item.evolution_rendement_tournesol'
                  )}
                />
              </ul>
            </details>
          </li>
        )}

        {recolte === 'automne' && (
          <li>
            <details>
              <summary>
                <img
                  className="tw-w-7 tw-min-h-6 tw-h-auto"
                  src="https://res.cloudinary.com/comparateuragricole/image/upload/v1689182255/moisson-live/markers/corn.png"
                  alt={'Marker maïs grain'}
                />
                {t('previsions.menu.item.titre_mais_grain')}
              </summary>
              <ul>
                <LayerToggleItem
                  layer={'rendement_mais_grain'}
                  label={t('previsions.menu.item.rendement_mais_grain')}
                />
                <LayerToggleItem
                  layer={'evolution_rendement_mais_grain'}
                  label={t(
                    'previsions.menu.item.evolution_rendement_mais_grain'
                  )}
                />
              </ul>
            </details>
          </li>
        )}

        {recolte === 'automne' && (
          <li>
            <details>
              <summary>
                <img
                  className="tw-w-7 tw-min-h-6 tw-h-auto"
                  src="https://res.cloudinary.com/comparateuragricole/image/upload/v1689182255/moisson-live/markers/mais_fourrage.png"
                  alt={'Marker maïs grain'}
                />
                {t('previsions.menu.item.titre_mais_fourrage')}
              </summary>
              <ul>
                <LayerToggleItem
                  layer={'rendement_mais_fourrage'}
                  label={t('previsions.menu.item.rendement_mais_fourrage')}
                />
                <LayerToggleItem
                  layer={'evolution_rendement_mais_fourrage'}
                  label={t(
                    'previsions.menu.item.evolution_rendement_mais_fourrage'
                  )}
                />
              </ul>
            </details>
          </li>
        )}
      </Dropdown.List>
    </Dropdown.Dropdown>
  );
}

type LayerToggleItemProps = {
  layer: Layers['previsions'];
  label: string;
  image?: string | ReactNode;
  alt?: string;
  className?: string;
};

function LayerToggleItem({
  className,
  layer,
  image,
  alt,
  label,
}: LayerToggleItemProps) {
  const { layers, change } = useLayersConfig();

  return (
    <Dropdown.SelectableItem
      className={className}
      key={layer}
      onClick={() => {
        change({
          previsions: layer,
          // On force le filtre lorsqu'on sélectionne une carte de résultat pour éviter la confusion lors de la lecture de la carte
          observations: getCorrespondingCulture(layer),
        });
      }}
      isActive={layers.previsions === layer || layers.previsions === 'hide'}
      image={image ?? <div />} // fallback to div to remove placeholder image
      alt={alt}
      label={label}
    />
  );
}

function getCorrespondingCulture(
  layer: Layers['previsions']
): Layers['observations'] {
  switch (true) {
    case layer.endsWith('colza'):
      return 'colza';
    case layer.endsWith('ble_tendre'):
      return 'ble_tendre';
    case layer.endsWith('ble_dur'):
      return 'ble_dur';
    case layer.endsWith('orge_hiver'):
      return 'orge_hiver';
    case layer.endsWith('orge_printemps'):
      return 'orge_printemps';
    default:
      return 'all';
  }
}

export function PrevisionsLegende() {
  const { layers } = useLayersConfig();
  const { t } = useTranslation();

  if (layers.previsions === 'hide') {
    return null;
  }

  const echelle = getEchelleForLayer(layers.previsions);

  return (
    <section className="tw-min-w-60">
      <h3 className="tw-card-title tw-text-center tw-w-full">
        {t('previsions.legend.title')}
      </h3>

      <div className="tw-overflow-x-auto">
        <table className="tw-table tw-table-xs">
          <caption className="tw-caption tw-caption-top tw-text-center">
            {t(`previsions.legend.caption.${layers.previsions}`, {
              annee: getCurrentHarvestYear(),
            })}
          </caption>

          <thead>
            <tr>
              <th></th>
              <th className="tw-text-right">
                {layers.previsions.startsWith('evolution_rendement')
                  ? t('previsions.legend.column_label.evolution_rendement')
                  : t('previsions.legend.column_label.rendement')}
              </th>
            </tr>
          </thead>

          <tbody>
            {echelle?.lignes.map((ligne, index) => {
              if ('min' in ligne && 'max' in ligne) {
                const { min, max } = ligne;
                return (
                  <Row
                    key={index}
                    color={ligne.couleur}
                    rendement={t('previsions.legend.column_value.de_a', {
                      min,
                      max,
                      unite: ligne.unite,
                      signe_min: echelle.afficherSigne && min > 0 ? '+' : '',
                      signe_max: echelle.afficherSigne && max > 0 ? '+' : '',
                    })}
                  />
                );
              }

              if ('min' in ligne) {
                const { min } = ligne;

                return (
                  <Row
                    key={index}
                    color={ligne.couleur}
                    rendement={t('previsions.legend.column_value.plus_de', {
                      min,
                      unite: ligne.unite,
                      signe: echelle.afficherSigne && min > 0 ? '+' : '',
                    })}
                  />
                );
              }

              const { max } = ligne;
              return (
                <Row
                  key={index}
                  color={ligne.couleur}
                  rendement={t('previsions.legend.column_value.moins_de', {
                    max,
                    unite: ligne.unite,
                    signe: echelle.afficherSigne && max > 0 ? '+' : '',
                  })}
                />
              );
            })}

            <Row
              color={palette.fallback}
              rendement={t('previsions.legend.column_value.pas_de_donnees')}
            />
          </tbody>
        </table>
      </div>
    </section>
  );
}

type RowProps = {
  color: string;
  rendement: string;
};

function Row({ color, rendement }: RowProps) {
  return (
    <tr className="tw-text-right">
      <td>
        <div
          className={'tw-h-3 tw-w-3 tw-rounded-full tw-border'}
          style={{
            backgroundColor: color,
            borderColor: color,
          }}
        />
      </td>
      <td>{rendement}</td>
    </tr>
  );
}
