import { LatLngBoundsExpression } from 'leaflet';
import { Coordinates } from './api/common';
import { HarvestYear } from './configuration/seasons';

export enum PaneName {
  Previsions = 'previsions',
  Observations = 'observations',
  Labels = 'labels',
}

export enum ZIndex {
  Previsions = 200,
  Labels = 215,
  Observations = 225,
  Partner = 250,
  Floating = 800,
  Dropdown = 900,
}

export type CountryCode = 'fr' | 'uk';

export function getCountryCode(): CountryCode {
  const override = process.env.REACT_APP_COUNTRY_CODE ?? undefined;

  if (override) {
    return override as CountryCode;
  }

  /* eslint-disable no-restricted-globals */
  if (location.hostname.includes('harvest-live')) {
    return 'uk';
  }

  return 'fr';
}

export type Recolte = 'ete' | 'automne';

function isRecolte(value: string | null): value is Config['recolte'] {
  return ['ete', 'automne'].includes(value ?? '');
}

export type Config = {
  country: CountryCode;
  lang: 'en' | 'fr';
  recolte: Recolte;
  annee: HarvestYear;
  whatsApp: {
    enabled: boolean;
    url?: string;
  };
  theFarmingForum: {
    enabled: boolean;
    threadUrl?: string;
  };
  tos: {
    enabled: boolean;
    faqEnabled: boolean;
    url?: string;
  };
  map: {
    attribution: string;
    layers: {
      noLabels: string;
      labels?: string;
    };
    position: Coordinates;
    bounds: LatLngBoundsExpression;
  };
  sensors: {
    url: string;
    amountOnMap: number;
  };
  logos: {
    squareWhite: string;
    squareBlack: string;
    horizontal: string;
  };
  aboutUs: {
    contactEmail: string;
  };
  concours: {
    enabled: boolean;
    reglementUrl: string;
  };
  resultats: {
    enabled: boolean;
    iframeUrl?: string;
  };
};

export function getConfig(): Config {
  const recolte = getRecolte();
  const annee = getAnnee();

  return {
    country: 'fr',
    lang: 'fr',
    recolte,
    annee,
    whatsApp: {
      enabled: true,
      url: 'https://chat.whatsapp.com/JjO6uabSBzNKel5UfUGwjd',
    },
    theFarmingForum: {
      enabled: false,
    },
    tos: {
      enabled: true,
      faqEnabled: false,
      url: 'https://res.cloudinary.com/comparateuragricole/image/upload/v1687294362/moisson-live/Mentions_l%C3%A9gales_et_politique_de_confidentialit%C3%A9_wk2tqm.pdf',
    },
    map: {
      bounds: westernEurope,
      position: { latitude: 48.45106561953216, longitude: 1.50360210537829 },
      attribution:
        '<a target="_blank" rel="noreferrer noopener" href="https://www.openstreetmap.org/copyright">© OpenStreetMap contributors</a>',
      layers: {
        noLabels: 'https://tile.openstreetmap.org/{z}/{x}/{y}.png',
      },
    },
    sensors: {
      url: 'https://bit.ly/3Cv7nIp',
      amountOnMap: 150,
    },
    logos: {
      squareWhite:
        'https://res.cloudinary.com/comparateuragricole/image/upload/v1688049126/moisson-live/logo_mobile_white_sajdxv.svg',
      squareBlack:
        'https://res.cloudinary.com/comparateuragricole/image/upload/v1688049122/moisson-live/logo_mobile_black_be0kfc.svg',
      horizontal:
        'https://res.cloudinary.com/comparateuragricole/image/upload/v1688049154/moisson-live/logo_bfedhr.svg',
    },
    aboutUs: {
      contactEmail: 'pa.foreau@cereapro.com',
    },
    concours: {
      enabled: recolte === 'ete',
      reglementUrl:
        'https://res.cloudinary.com/comparateuragricole/image/upload/v1721397497/strapi/Moisson_Live_reglement_de_jeu_concours_32988e3f94.pdf',
    },
    resultats: {
      enabled: true,
      iframeUrl: getIframeUrl(annee, recolte),
    },
  };
}

const westernEurope = [
  // South West - To the west of the southern tip of Portugal
  [37.39125, -24.724893],
  // North East - Border between Sweden and Finland
  [65.730053, 28.140664],
] satisfies LatLngBoundsExpression;

const urls: Record<HarvestYear, Record<Recolte, string | undefined>> = {
  2024: {
    ete: 'https://infogram.com/resultat-orge-dhiver-moissonlive-2024-1h0n25okrqwrl4p?live',
    automne: undefined,
  },
};

export function getAnnee(): HarvestYear {
  const params = new URLSearchParams(window.location.search);
  const now = new Date();
  const isAfterJune = now.getMonth() >= 5;
  const harvestYear = isAfterJune ? now.getFullYear() : now.getFullYear() - 1;

  return parseInt(
    params.get('year') ??
      params.get('annee') ??
      harvestYear.toString() ??
      '2024',
    10
  );
}

function getRecolte(): Recolte {
  const params = new URLSearchParams(window.location.search);
  const rawRecolte = params.get('recolte');
  return isRecolte(rawRecolte) ? rawRecolte : 'automne';
}

function getIframeUrl(
  annee: HarvestYear,
  recolte: Recolte | null
): string | undefined {
  if (!recolte) {
    return undefined;
  }

  return urls[annee]?.[recolte] ?? undefined;
}
