// MyContext.tsx
import React, { createContext, PropsWithChildren, useContext } from 'react';
import type { Config } from '../../config';
import { getConfig } from '../../config';

// Create the context
const ConfigContext = createContext<Config | null>(null);

// Create the provider component
export function ConfigProvider({ children }: PropsWithChildren) {
  // TODO: remove hardcoding
  const value = getConfig();
  console.debug('[Config] Chargement de la config', value);

  return (
    <ConfigContext.Provider value={value}>{children}</ConfigContext.Provider>
  );
}

// TODO: [REFACTO] Rename this to useConfiguration
export function useConfig(): Config {
  const context = useContext(ConfigContext);

  if (!context) {
    throw new Error('useConfig must be used within a ConfigProvider');
  }

  return context;
}
