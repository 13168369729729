import React from 'react';
import { useTranslation } from 'react-i18next';
import { IoLeafSharp, IoSunnySharp } from 'react-icons/io5';

import { AboutUsButton } from '../../modules/partners';
import { ObservationsWithinBoxButton, WhatsAppButton } from './buttons';
import { useConfig } from '../../modules/config';
import classNames from 'classnames';
import cn from 'classnames';

import styles from './styles.module.scss';
import { FaqButton } from '../../modules/partners/about';
import { TimelineButton } from '../../modules/timeline';
import { ErrorBoundary } from '@sentry/react';
import { ResultatsButton } from '../../modules/resultats';

// TODO: [REFACTO] make this a collapsible menu in responsible mode
function TopBar() {
  const { t } = useTranslation();
  const config = useConfig();
  return (
    <div
      className={classNames(styles.wrapper, 'tw-flex tw-items-center tw-gap-2')}
    >
      <img
        src={config.logos.horizontal}
        alt={t('common.website.alt_logo')}
        className={styles.logo}
      />
      <img
        src={config.logos.squareWhite}
        alt={t('common.website.alt_logo')}
        className={styles.logoResponsive}
      />

      <div className={classNames(styles.buttons, 'tw-flex tw-gap-x-2')}>
        <ErrorBoundary>
          <ChangementRecoleButton />
        </ErrorBoundary>
        <ErrorBoundary>
          <TimelineButton />
        </ErrorBoundary>
        <ErrorBoundary>
          {config.resultats.enabled && <ResultatsButton />}
        </ErrorBoundary>
        <ErrorBoundary>
          {config.whatsApp.enabled && (
            <WhatsAppButton url={config.whatsApp.url} />
          )}
        </ErrorBoundary>
        <ErrorBoundary>
          <ObservationsWithinBoxButton />
        </ErrorBoundary>
        <ErrorBoundary>{config.tos.faqEnabled && <FaqButton />}</ErrorBoundary>
        <ErrorBoundary>
          <AboutUsButton />
        </ErrorBoundary>
      </div>
    </div>
  );
}

function ChangementRecoleButton() {
  const { t } = useTranslation();
  const { recolte, annee } = useConfig();

  const href = new URL(window.location.href);

  const next = recolte === 'ete' ? 'automne' : 'ete';
  href.searchParams.set('recolte', next);

  return (
    <a
      href={href.toString()}
      className={cn(
        'tw-flex tw-gap-1.5 tw-items-center tw-text-white tw-p-2 tw-rounded-lg',
        'hover:tw-text-white hover:tw-no-underline'
      )}
      title={t('changement_recolte_bouton.titre_' + recolte, {
        annee,
      })}
    >
      <div className="tw-flex tw-items-center tw-gap-1">
        <IoSunnySharp />
        <span className="tw-hidden sm:tw-inline-block">
          {t('changement_recolte_bouton.ete', {
            annee,
          })}
        </span>
      </div>
      <input
        type="checkbox"
        className={cn(
          'tw-toggle tw-toggle-xs tw-pointer-events-none',
          'tw-border-primary tw-bg-primary',
          'hover:tw-border-warning hover:tw-bg-warning'
        )}
        checked={recolte === 'automne'}
        readOnly={true}
      />
      <div className="tw-flex tw-items-center tw-gap-1">
        <IoLeafSharp />
        <span className="tw-hidden sm:tw-inline-block">
          {t('changement_recolte_bouton.automne', {
            annee,
          })}
        </span>
      </div>
    </a>
  );
}

export default TopBar;
