import * as Sentry from '@sentry/react';
import { Form } from 'redux-form';
import * as Api from './api';
import { ObservationDetails } from './api';
import { cultures } from '../../constants/observation';
import { parseAndFormat } from '../../utils/phoneUtil';
import { getCountryCode } from '../../config';
import Period from '../../models/Period';
import { HydraElement } from '../../api/common';
import { Culture } from '../layers';

// TODO: use a single API call for all cultures
// TODO: add proper types for form values
export function submit(
  culture: Culture,
  form: Form
): Promise<HydraElement<ObservationDetails>> {
  if (culture === cultures.RAPESEED) {
    return Api.createRapeseedObservation(createRapeseedPayload(form));
  }

  if (culture === cultures.SUNFLOWER) {
    return Api.createSunflowerObservation(createSunflowerPayload(form));
  }

  if (culture === cultures.MAIS_GRAIN) {
    return Api.createMaisGrainObservation(createMaisGrainPayload(form));
  }

  if (culture === cultures.MAIS_FOURRAGE) {
    return Api.createMaisFourrageObservation(createMaisFourragePayload(form));
  }

  if (culture === cultures.BARLEY) {
    return Api.createBarleyObservation(createBarleyPayload(form));
  }

  if (culture === cultures.WHEAT) {
    return Api.createWheatObservation(createWheatPayload(form));
  }

  Sentry.captureMessage(`Unknown culture ${culture}`, {
    level: 'error',
    extra: {
      culture,
    },
  });

  return Promise.reject();
}

function createRapeseedPayload(form: any) {
  return {
    ...form,
    specificWeight: parseFloat(form.specificWeight),
    protein: parseFloat(form.protein),
    fallingNumber: parseFloat(form.fallingNumber),
    yield: parseFloat(form.yield),
    nitrogenQuantityUsed: parseFloat(form.nitrogenQuantityUsed),
    humidity: parseFloat(form.humidity),
    place: form.place.label,
    phone: form.phone ? parseAndFormat(form.phone) : undefined,
    targetPrice: form.targetPrice ? parseFloat(form.targetPrice) : undefined,
    town: form.place?.value['@id'],
    country: getCountryCode(),
    sowingWeek:
      form.sowingWeekBegin && form.sowingWeekEnd
        ? new Period({ begin: form.sowingWeekBegin, end: form.sowingWeekEnd })
        : undefined,
  };
}

function createMaisGrainPayload(form: any) {
  return {
    ...form,
    protein: parseFloat(form.protein),
    fallingNumber: parseFloat(form.fallingNumber),
    yield: parseFloat(form.yield),
    nitrogenQuantityUsed: parseFloat(form.nitrogenQuantityUsed),
    humidity: parseFloat(form.humidity),
    place: form.place.label,
    phone: form.phone ? parseAndFormat(form.phone) : undefined,
    targetPrice: form.targetPrice ? parseFloat(form.targetPrice) : undefined,
    town: form.place?.value['@id'],
    country: getCountryCode(),
    sowingWeek:
      form.sowingWeekBegin && form.sowingWeekEnd
        ? new Period({ begin: form.sowingWeekBegin, end: form.sowingWeekEnd })
        : undefined,
  };
}

function createMaisFourragePayload(form: any) {
  return {
    ...form,
    protein: parseFloat(form.protein),
    fallingNumber: parseFloat(form.fallingNumber),
    yield: parseFloat(form.yield),
    nitrogenQuantityUsed: parseFloat(form.nitrogenQuantityUsed),
    humidity: parseFloat(form.humidity),
    place: form.place.label,
    phone: form.phone ? parseAndFormat(form.phone) : undefined,
    targetPrice: form.targetPrice ? parseFloat(form.targetPrice) : undefined,
    town: form.place?.value['@id'],
    country: getCountryCode(),
    indicePrecocite: form.indicePrecocite
      ? parseInt(form.indicePrecocite, 10)
      : undefined,
    ensilagePlanteEntiere: parseBoolean(form.ensilagePlanteEntiere),
    sowingWeek:
      form.sowingWeekBegin && form.sowingWeekEnd
        ? new Period({ begin: form.sowingWeekBegin, end: form.sowingWeekEnd })
        : undefined,
  };
}

function createSunflowerPayload(form: any) {
  return {
    ...form,
    specificWeight: parseFloat(form.specificWeight),
    protein: parseFloat(form.protein),
    fallingNumber: parseFloat(form.fallingNumber),
    yield: parseFloat(form.yield),
    nitrogenQuantityUsed: parseFloat(form.nitrogenQuantityUsed),
    humidity: parseFloat(form.humidity),
    place: form.place.label,
    phone: form.phone ? parseAndFormat(form.phone) : undefined,
    targetPrice: form.targetPrice ? parseFloat(form.targetPrice) : undefined,
    town: form.place?.value['@id'],
    country: getCountryCode(),
    sowingWeek:
      form.sowingWeekBegin && form.sowingWeekEnd
        ? new Period({ begin: form.sowingWeekBegin, end: form.sowingWeekEnd })
        : undefined,
  };
}

function createBarleyPayload(form: any) {
  return {
    ...form,
    specificWeight: parseFloat(form.specificWeight),
    yield: parseFloat(form.yield),
    nitrogenQuantityUsed: parseFloat(form.nitrogenQuantityUsed),
    humidity: parseFloat(form.humidity),
    phone: form.phone ? parseAndFormat(form.phone) : undefined,
    place: form.place.label,
    targetPrice: form.targetPrice ? parseFloat(form.targetPrice) : undefined,
    town: form.place?.value['@id'],
    country: getCountryCode(),
    grainsRetainedAfterScreening: form.grainsRetainedAfterScreening
      ? parseFloat(form.grainsRetainedAfterScreening)
      : undefined,
    sowingWeek:
      form.sowingWeekBegin && form.sowingWeekEnd
        ? new Period({ begin: form.sowingWeekBegin, end: form.sowingWeekEnd })
        : undefined,
  };
}

function createWheatPayload(form: any) {
  return {
    ...form,
    specificWeight: parseFloat(form.specificWeight),
    protein: parseFloat(form.protein),
    fallingNumber: parseFloat(form.fallingNumber),
    yield: parseFloat(form.yield),
    nitrogenQuantityUsed: parseFloat(form.nitrogenQuantityUsed),
    humidity: parseFloat(form.humidity),
    place: form.place.label,
    phone: form.phone ? parseAndFormat(form.phone) : undefined,
    targetPrice: form.targetPrice ? parseFloat(form.targetPrice) : undefined,
    town: form.place?.value['@id'],
    country: getCountryCode(),
    sowingWeek:
      form.sowingWeekBegin && form.sowingWeekEnd
        ? new Period({ begin: form.sowingWeekBegin, end: form.sowingWeekEnd })
        : undefined,
  };
}

function parseBoolean(value: string): undefined | boolean {
  return value === 'true' ? true : value === 'false' ? false : undefined;
}
