import React from 'react';
import { connect } from 'react-redux';
import { usePlace } from '../../../../../modules/place/usePlace';
import { useEmail, usePhone } from '../../../../../modules/contacts';
import {
  getCategory,
  getCulture,
  getType,
} from '../../../../../selectors/ui/modal/observation/creation';
import { cultures, types } from '../../../../../constants/observation';
import WheatObservationForm from '../harvest/WheatObservationForm';
import BarleyObservationForm from '../harvest/BarleyObservationForm';
import SunflowerObservationForm from '../harvest/SunflowerObservationForm';
import RapeseedObservationForm from '../harvest/RapeseedObservationForm';
import LatLng from '../../../../../models/latLng';
import { getCityLabel } from '../../../../../api/city';
import MaisGrainObservationForm from '../harvest/MaisGrainObservationForm';
import MaisFourrageObservationForm from '../harvest/MaisFourrageObservationForm';

const ObservationForm = ({
  type,
  culture,
  category,
  previousStep,
  onSubmit,
}) => {
  const { getPlace } = usePlace();
  const { getEmail } = useEmail();
  const { getPhone } = usePhone();

  const onPreviousStep = (previousStep) => {
    previousStep();
  };

  const place = getPlace() ?? undefined;
  const email = getEmail() ?? undefined;
  const phone = getPhone() ?? undefined;
  const coordinates = place
    ? new LatLng({
        longitude: place.coordinates.longitude,
        latitude: place.coordinates.latitude,
      })
    : new LatLng();
  const placeValue = place
    ? {
        value: place,
        label: getCityLabel(place),
      }
    : undefined;

  // The screen allowing the user to choose the type of observation has been removed (temporarily?)
  type = type ?? types.HARVEST;
  switch (type) {
    case types.HARVEST:
      switch (culture) {
        case cultures.WHEAT:
          return (
            <WheatObservationForm
              previousStep={() => onPreviousStep(previousStep)}
              initialValues={{
                category: category,
                coordinates: coordinates,
                place: placeValue,
                email: email,
                phone: phone,
                town: null,
              }}
              onSubmit={onSubmit}
              enableReinitialize={true}
            />
          );
        case cultures.BARLEY:
          return (
            <BarleyObservationForm
              previousStep={previousStep}
              initialValues={{
                category: category,
                coordinates: coordinates,
                place: placeValue,
                email: email,
                phone: phone,
                town: null,
              }}
              onSubmit={onSubmit}
              enableReinitialize={true}
            />
          );
        case cultures.SUNFLOWER:
          return (
            <SunflowerObservationForm
              previousStep={previousStep}
              onSubmit={onSubmit}
              enableReinitialize={true}
              initialValues={{
                category: category,
                coordinates: coordinates,
                place: placeValue,
                email: email,
                phone: phone,
                town: null,
              }}
            />
          );
        case cultures.RAPESEED:
          return (
            <RapeseedObservationForm
              previousStep={previousStep}
              onSubmit={onSubmit}
              enableReinitialize={true}
              initialValues={{
                category: category,
                coordinates: coordinates,
                place: placeValue,
                email: email,
                phone: phone,
                town: null,
              }}
            />
          );
        case cultures.MAIS_GRAIN:
          return (
            <MaisGrainObservationForm
              previousStep={previousStep}
              onSubmit={onSubmit}
              enableReinitialize={true}
              initialValues={{
                category: category,
                coordinates: coordinates,
                place: placeValue,
                email: email,
                phone: phone,
                town: null,
              }}
            />
          );
        case cultures.MAIS_FOURRAGE:
          return (
            <MaisFourrageObservationForm
              previousStep={previousStep}
              onSubmit={onSubmit}
              enableReinitialize={true}
              initialValues={{
                category: category,
                coordinates: coordinates,
                place: placeValue,
                email: email,
                phone: phone,
                town: null,
              }}
            />
          );
        default:
          return null;
      }
    default:
      return null;
  }
};

export default connect((state) => ({
  culture: getCulture(state),
  category: getCategory(state),
  type: getType(state),
}))(ObservationForm);
