import React from 'react';

import styles from './styles.module.scss';

import feedWheat from '../../../../images/feed-wheat.jpg';
import durumWheat from '../../../../images/durum-wheat.jpg';
import rapeseed from '../../../../images/rapeseed.jpg';
import sunflower from '../../../../images/sunflower.jpg';
import barley from '../../../../images/barley.jpg';
import corn from '../../../../images/corn.jpg';

import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import { cultures } from '../../../../constants/observation';
import { categories as wheatCategories } from '../../../../constants/wheat';
import { categories as barleyCategories } from '../../../../constants/barley';
import NavigationBar from '../NavigationBar';
import SectionTitle from '../SectionTitle';
import {
  nextStepAction,
  setCategoryAction,
  setCultureAction,
} from '../../../../actions/ui/modal/observation/creation';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useConfig } from '../../../../modules/config';

const CultureSelection = ({ previousStep }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { recolte } = useConfig();

  const handleClick = ({ culture, category }) => {
    dispatch(setCultureAction(culture));
    dispatch(setCategoryAction(category));
    dispatch(nextStepAction());
  };

  return (
    <section>
      <SectionTitle
        title={t('modale_creation_observation.culture.section_label')}
      />
      <Row>
        {recolte === 'ete' && (
          <>
            <Col xs={6} md={4} lg={3} className={styles.col}>
              <Button
                variant="secondary"
                className="!tw-flex tw-flex-col tw-w-full tw-items-center tw-justify-center"
                onClick={() =>
                  handleClick({
                    culture: cultures.WHEAT,
                    category: wheatCategories.FEED,
                  })
                }
              >
                <img
                  src={feedWheat}
                  alt={cultures.WHEAT}
                  className={styles.image}
                />
                <p className={styles.label}>
                  {t('observation.culture.feed_wheat')}
                </p>
              </Button>
            </Col>
            <Col xs={6} md={4} lg={3} className={styles.col}>
              <Button
                variant="secondary"
                className="!tw-flex tw-flex-col tw-w-full tw-items-center tw-justify-center"
                onClick={() =>
                  handleClick({
                    culture: cultures.WHEAT,
                    category: wheatCategories.DURUM,
                  })
                }
              >
                <img
                  src={durumWheat}
                  alt={cultures.WHEAT}
                  className={styles.image}
                />
                <p className={styles.label}>
                  {t('observation.culture.durum_wheat')}
                </p>
              </Button>
            </Col>
            <Col xs={6} md={4} lg={3} className={styles.col}>
              <Button
                variant="secondary"
                className="!tw-flex tw-flex-col tw-w-full tw-items-center tw-justify-center"
                onClick={() => handleClick({ culture: cultures.RAPESEED })}
              >
                <img
                  src={rapeseed}
                  alt={cultures.RAPESEED}
                  className={styles.image}
                />
                <p className={styles.label}>
                  {t('observation.culture.rapeseed')}
                </p>
              </Button>
            </Col>
            <Col xs={6} md={4} lg={3} className={styles.col}>
              <Button
                variant="secondary"
                className="!tw-flex tw-flex-col tw-w-full tw-items-center tw-justify-center"
                onClick={() =>
                  handleClick({
                    culture: cultures.BARLEY,
                    category: barleyCategories.WINTER,
                  })
                }
              >
                <img
                  src={barley}
                  alt={cultures.BARLEY}
                  className={styles.image}
                />
                <p className={styles.label}>
                  {t('observation.culture.winter_barley')}
                </p>
              </Button>
            </Col>
            <Col xs={6} md={4} lg={3} className={styles.col}>
              <Button
                variant="secondary"
                className="!tw-flex tw-flex-col tw-w-full tw-items-center tw-justify-center"
                onClick={() =>
                  handleClick({
                    culture: cultures.BARLEY,
                    category: barleyCategories.SPRING,
                  })
                }
              >
                <img
                  src={barley}
                  alt={cultures.BARLEY}
                  className={styles.image}
                />
                <p className={styles.label}>
                  {t('observation.culture.spring_barley')}
                </p>
              </Button>
            </Col>
          </>
        )}

        {recolte === 'automne' &&
          getCulturesAutomne(t).map((culture) => (
            <Col xs={6} md={4} lg={3} className={styles.col} key={culture.slug}>
              <Button
                variant="secondary"
                className="!tw-flex tw-flex-col tw-w-full tw-items-center tw-justify-center"
                onClick={() => handleClick({ culture: culture.slug })}
              >
                <img
                  src={culture.image}
                  alt={culture.slug}
                  className={styles.image}
                />
                <p className={styles.label}>{culture.label}</p>
              </Button>
            </Col>
          ))}
      </Row>
      <NavigationBar previousStep={previousStep} />
    </section>
  );
};

const getCulturesAutomne = (t) => [
  {
    slug: cultures.SUNFLOWER,
    image: sunflower,
    label: t('observation.culture.sunflower'),
  },
  {
    slug: cultures.MAIS_GRAIN,
    image: corn,
    label: t('observation.culture.mais_grain'),
  },
  {
    slug: cultures.MAIS_FOURRAGE,
    image: corn,
    label: t('observation.culture.mais_fourrage'),
  },
];

export default CultureSelection;
