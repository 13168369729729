import { getAnnee } from '../config';

/**
 * @deprecated Use {@see useConfig} or {@see getAnnee} instead.
 */
export function getCurrentHarvestYear(): HarvestYear {
  return getAnnee();
}

export type HarvestYear = number;
